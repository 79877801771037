<template>
    <q-layout>
        <q-page-container>
        <q-page class="flex flex-center">
            <div class="text-center">
                <h1 style="margin:0">404</h1>
                <h5 style="margin:0">Нет такой станицы.</h5>
            </div>
        </q-page>
        </q-page-container>
    </q-layout>
</template>

<script>

export default {
  
    data () {
        return {
        }
    }
    
}
</script>